import React, { useEffect } from 'react';
import { Box, Collapse, IconButton, styled, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';

//Icon Transition
const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.longest,
    }),
}));

//Provides a dropdown for expandable Content
const ExpandContent = (props) => {
    //state
    const [expanded, setExpanded] = React.useState(props.initState === true ? true : false);

    //monotir for parent change of state
    useEffect(() => {
        if (props.open === true || props.open === false) {
            setExpanded(props.open);
        }
    }, [props.open]);

    //Click Handler
    const handleExpandClick = () => {
        setExpanded(!expanded);
        if (props.stateChangeCallback) {
            props.stateChangeCallback(!expanded);
        }
    };

    return (
        <React.Fragment>
            <Box
                display='flex'
                alignItems='center'
                onClick={() => {
                    if (props.clickAnywhere === true) handleExpandClick();
                }}
            >
                <ExpandMore expand={expanded} onClick={handleExpandClick}>
                    <ExpandMoreIcon />
                </ExpandMore>
                <Typography variant={props.labelStyle != null ? props.labelStyle : 'h5'} display='inline' width='100%'>
                    {props.label ? props.label : 'Expand Content'}
                </Typography>
            </Box>

            <Collapse in={expanded} timeout='auto' unmountOnExit>
                <Box padding={1}>{props.children}</Box>
            </Collapse>
        </React.Fragment>
    );
};

//props validation
ExpandContent.propTypes = {
    //The label of the expander
    label: PropTypes.string.isRequired,

    //The content of the expander
    children: PropTypes.element.isRequired,

    //The style of the label
    labelStyle: PropTypes.string,

    //The default state of the expander where true === open
    initState: PropTypes.bool,

    //Callback function fired on open change
    stateChangeCallback: PropTypes.func,

    //Controls the state of open from the parent
    open: PropTypes.bool,
};

export default ExpandContent;
