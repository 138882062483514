import React, { useContext } from 'react';
import {Box, Typography, Stack, useTheme, List, ListItemButton, ListItemIcon, Grow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import LogoutIcon from '@mui/icons-material/Logout';
import { UserContext, AuthContext, CompanyContext } from '../Root';
import PopContent from './utils/util.pop';
import { LinkNoText } from './styledComponents/linkNoText';
import { HeaderBar } from './styledComponents/HeaderBar';
import { RoundedBox } from './styledComponents/RoundedBox';

const useStyles = makeStyles((theme) => ({
    rounding: {
        borderRadius: '20px 45px 15px 45px',
        background: 'white',
        maxWidth: 130,
    },
}));

const Header = (props) => {
    //context
    const userContext = useContext(UserContext);
    const signOut = useContext(AuthContext);
    const companyContext = useContext(CompanyContext)

    //styles
    const classes = useStyles();
    const theme = useTheme();

    //sign out function
    const sign_out = () => {
        signOut();
    };

    return (
        
        <HeaderBar className={classes.header} elevation={9} >

                <Box flex={1}>
                    <a href={'/#/'}>
                        <Box className={classes.rounding} padding={1.75} position={'relative'}>
                            <img
                                src={process.env.PUBLIC_URL + '/logos/logoWithoutSubtext.svg'}
                                style={{ maxWidth: '100%', maxHeight: 30 }}
                                alt='Air-Weigh'
                            />
                            {userContext.awAdmin && (
                                <Box
                                    paddingX={0.2}
                                    position='absolute'
                                    bottom={3}
                                    right={3}
                                    borderRadius={10}
                                    backgroundColor={theme.palette.secondary.main}
                                >
                                    <Typography paddingX={0.25} margin={0} color='white' variant='caption'>
                                        Admin
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </a>
                </Box>

                <Grow in={companyContext.company !== false} timeout={1000} mountOnEnter unmountOnExit>
                    <Box flex={1} display='flex' justifyContent='center' alignItems='center' marginBottom={2}>
                        <RoundedBox  backgroundColor='white' marginTop={true} display='flex'>
                                <Typography variant='subtitle1' color={theme.palette.grey[700]}>
                                    <b>
                                    Customer: 
                                    </b>
                                </Typography> 
                                &nbsp; 
                                <Typography variant='button' sx={{backgroundColor:theme.palette.primary.light, borderRadius:10, paddingX:1,marginY:0.4}} color={theme.palette.primary.contrastText}> 
                                    {companyContext.company}
                                </Typography>
                        </RoundedBox>
                    </Box>
                </Grow>

                <Box flex={1} display='flex' justifyContent='flex-end' paddingRight={3}>
                    <PopContent
                        label={
                            <Stack alignItems='center'>
                                <AccountCircleTwoToneIcon fontSize='large' />
                                <Typography color={theme.palette.primary.contrastText}>
                                    Welcome {userContext.username}
                                </Typography>
                            </Stack>
                        }
                    >
                        <List>
                            <LinkNoText href={`/#/user?userID=${userContext.username}`}>
                                <ListItemButton>User Profile</ListItemButton>
                            </LinkNoText>
                            <ListItemButton onClick={sign_out}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                Logout
                            </ListItemButton>
                        </List>
                    </PopContent>
                </Box>

        </HeaderBar>
    );
};

export default Header;