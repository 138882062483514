import { Paper } from "@mui/material";
import { styled,  } from "@mui/styles";

export const HeaderBar = styled(Paper)(({ theme }) => ({
    position: 'sticky', //transition between fixed and relative positioning depending on scroll
    top: 0, //keep the fixed positioning relative to the top
    minWidth: '100%',
    backgroundColor: theme.palette.primary.main,
    zIndex: 9999, //arbitrary value used to keep the header above all other content,
    display:'flex',
    justifyContent:'space-between',
    padding:3,
}));