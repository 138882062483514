import React from 'react';
import { List, Divider, ListItemText } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListIcon from '@mui/icons-material/List';

const CollapseList = (props) => {
    const [open, setOpen] = React.useState(true);

    const handleExpand = () => {
        setOpen(!open);
    };

    return (
        <React.Fragment>
            <ListItemButton onClick={handleExpand} sx={{ paddingLeft: 0 }}>
                <ListIcon />
                <ListItemText sx={{ paddingLeft: 1 }} primary={props.title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                    {props.children}
                </List>
            </Collapse>
            <Divider />
        </React.Fragment>
    );
};
export default CollapseList;
