import React from "react";
import { Skeleton } from "@mui/material";

const TableLoader = (props) =>{
    return(
        <React.Fragment>
            <Skeleton animation="pulse" height={30}/>
            <Skeleton animation="pulse" height={100}/>
        </React.Fragment>
        
    )
}

export default TableLoader