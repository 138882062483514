import { useTheme } from "@mui/styles";
import React from "react";

const LinkNoText = (props) =>{
    const theme = useTheme()
    return(
        <a href={props.href} style={{'textDecoration':'none',color:theme.palette.common.black}}>
            {props.children}
        </a>
    )
}

export {LinkNoText}