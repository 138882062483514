import React, { useState } from 'react';
import { Authenticator, View, useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LinkIcon from '@mui/icons-material/Link';
import { useTheme } from '@mui/styles';
import Root from './Root.js';
import './index.css';
import ExpandContent from './components/utils/util.expander.jsx';

const HomePage = (props) => {
    const { route } = useAuthenticator((context) => [context.route]);
    const GlobalTheme = useTheme();

    const components = {
        Header() {
            return (
                <Box marginTop={5} sx={{ display: { lg: 'block', xl: 'none' } }}>
                    <img src={process.env.PUBLIC_URL + '/logos/logoGradientNoSubtext.svg'} />
                </Box>
            );
        },
        Footer() {
            return (
                <View textAlign='center' padding={3}>
                    <Typography>&copy; All Rights Reserved</Typography>
                </View>
            );
        },

        SignIn: {
            Header() {
                return (
                    <Box paddingTop={2} paddingLeft={2}>
                        <Typography variant='h6'>Username & Password</Typography>
                    </Box>
                );
            },
        },
    };

    //appearance of the authenticator is controlled by the Amplify Theme in the parent component
    const getAuthenticator = () => {
        return (
            <Authenticator components={components} hideSignUp={true}>
                {({ signOut, user }) => {
                    //render the app
                    return <Root signOut={signOut} user={user} />;
                }}
            </Authenticator>
        );
    };

    const v1 = () => {
        return (
            <Stack width='100%' height='100vh' minWidth='700px' justifyContent='center' alignItems='center' spacing={5} >
                <Paper elevation={10} sx={{ padding: 2, width: '75%', maxWidth: '1200px' }}>
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        marginY={2}
                        sx={{ display: { sm: 'block', md: 'none' } }}
                    >
                        <Typography variant='h3' align='center' color={GlobalTheme.palette.darkBlue.main}>
                            <b> Login to start managing your fleet today.</b>
                        </Typography>
                    </Box>

                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        marginY={2}
                        maxWidth={'100%'}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                    >
                        <img src={process.env.PUBLIC_URL + '/logos/slogan.png'} style={{ maxWidth: '100%' }} />
                    </Box>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            xl={5.9}
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            {getAuthenticator()}
                        </Grid>
                        <Divider flexItem orientation='vertical' sx={{ marginRight: 0.5 }} />
                        <Grid item lg={0} xl={5.9} sx={{ display: { xs: 'none', xl: 'block' } }}>
                            <Box
                                display='flex'
                                justifyContent='center'
                                position='relative'
                                minHeight='100%'
                                minWidth='100%'
                            >
                                <img
                                    style={{
                                        maxHeight: '90%',
                                        maxWidth: '90%',
                                    }}
                                    src={process.env.PUBLIC_URL + '/logos/truckBranding.png'}
                                />
                                <Box position='absolute' bottom={75} right={250}>
                                    <Box
                                        position='absolute'
                                        backgroundColor={GlobalTheme.palette.darkBlue.dark}
                                        height='100px'
                                        width='300px'
                                    />
                                    <Box
                                        top={20}
                                        left={20}
                                        position='absolute'
                                        backgroundColor={GlobalTheme.palette.darkBlue.light}
                                        height='100px'
                                        width='300px'
                                        padding={1}
                                    >
                                        <img src={process.env.PUBLIC_URL + '/logos/logoWhiteNoSubtext.svg'} />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper elevation={3} sx={{ padding: 1, width: '75%', maxWidth: '1200px' }}>
                    <ExpandContent labelStyle='h6' clickAnywhere={true} label='Looking for the original BinMaxx portal?' >
                        <Stack spacing={1}>
                            <Typography>
                                If you have not upgraded to the latest version of the BinMaxx portal or are otherwise looking for the original version, you can access it with the following link:
                            </Typography>

                            <Typography justifyContent='center' display='flex'>
                                <a href='https://app.binmaxx.net' target='_blank'>
                                    <LinkIcon/>&nbsp;BinMaxx.net
                                </a>
                            </Typography>

                            <Typography>
                                If you're interested in a free upgrade to the latest version of the BinMaxx Portal, please reach out to AirWeigh-Support via any of the following contact methods:
                            </Typography>
                            <Typography justifyContent='center' display='flex'>
                                <a href = "mailto: support@air-weigh.com"><MailIcon/>&nbsp;support@air-weigh.com</a> &nbsp;&nbsp;<LocalPhoneIcon/> 888-459-3247
                            </Typography>
                        </Stack>
                    </ExpandContent>
                </Paper>
            </Stack>
        );
    };

    return <React.Fragment>{route === 'authenticated' ? getAuthenticator() : v1()}</React.Fragment>;
};

export default HomePage;
