
import { Box,useTheme } from '@mui/system';
import React, { useState, useEffect } from 'react';

const Footer = () => {

    //Define the current state of the component (year)
    //and a method for updating that state (setYear)
    //Also provide a default starting value of 2021
    const [year, setYear] = useState("2021");

    //theme
    const theme = useTheme()

    //After each render
    useEffect(() => {
		    setYear(new Date().getFullYear()) //update the state
	    }, [new Date().getFullYear()]); //skip updating if year hasn't changed
   
    return (
        <Box sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <footer className="text-white-50 fixed-bottom text-center" style={{backgroundColor: theme.palette.primary.main}}>
                <small>Copyright &copy; {year} by Air-Weigh On Board Scales.</small>
            </footer>
        </Box>
    )
}
export default Footer;