
import React,{useEffect, useState, createContext, useMemo, useCallback} from 'react';
import { Box, Stack, StyledEngineProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/layout.header.jsx'
import Footer from './components/layout.footer.jsx'
import Body from './components/layout.body.jsx'
import '@aws-amplify/ui-react/styles.css'
import "maplibre-gl/dist/maplibre-gl.css";
import { HashRouter } from 'react-router-dom';

//context setup
export const UserContext = createContext(); // holds the current user attributes
export const AuthContext = createContext(); //holds the function necessary for user signout
export const CompanyContext = createContext()

const Root = (props) => {

    //user attribute state/context is 
	const [userAttr,setUserAttr] = useState(false)

    //client info and context are used for AirWeigh Admins.
    //this allows client info to be passed down to components and only display
    //relevant information
    const [company,setCompany] = useState(false)
    const companyInfo = useMemo(()=>{
        return({
            id: company ? company.id : false,
            company: company ? company.name : false,
            updateClient: setCompany,
        })
    },[company])


    useEffect(() => {
        setUser(props.user)
    }, [props.user,props.signOut]);
    
	//extract the relevant current user attributes
	const setUser = (user) =>{
		try{
			if(user){

                //locate the user attributes list
                let attributesList = {}
                if(user.attributes){ //default case
                    attributesList = user.attributes
                }else if(user.challengeParam.userAttributes){ //instance where user changes password / new user login
                    attributesList = user.challengeParam.userAttributes
                }

                //parse the user
				var userAttributes = {'username': user.username}
				for(const [key, value] of Object.entries(attributesList) ){
					switch(key){
						case 'custom:admin':
							userAttributes[key.replace('custom:','')] = value === 'true' ? true : false
							break
						case 'custom:awAdmin':
							userAttributes[key.replace('custom:','')] = value === 'true' ? true : false
							break
						case 'custom:companyID':
							userAttributes[key.replace('custom:','')] = value
							break
					}
				}
				setUserAttr(userAttributes)
			}
			else{
				setUserAttr(false)
			}
		}
		catch{
			setUserAttr(false)
		}
	}

    const signOut = useCallback(()=>{

        //redirecting to root prevents Administrators from accessing undefined pages prior to company selection
        props.signOut()
        window.location = '/#/'
    },[props.signOut])

	//listening for Amplify Events
	//usage largely depreciated 11-23-21 due to new Amplify Authenticator component
	// Hub.listen('auth',(data)=>{
	// 	for(const [key, value] of Object.entries(data.payload) ){
	// 		switch(value){
	// 			case 'signOut':
	// 				setUser(false)
	// 				break
	// 		}
	// 	}
	// })

	return (
        <HashRouter>
            <StyledEngineProvider injectFirst>
                    <UserContext.Provider value={userAttr}>
                        <AuthContext.Provider value={signOut}>
                            <CompanyContext.Provider value={companyInfo}>
                                <CssBaseline />
                                <Box>
                                    <Stack >
                                        <Header/>
                                        <Body/>
                                        <Footer/>
                                    </Stack>
                                </Box>
                            </CompanyContext.Provider>
                        </AuthContext.Provider>
                    </UserContext.Provider>
            </StyledEngineProvider>
        </HashRouter>

                
    );
}
export default (Root);

