import { createTheme, alpha, responsiveFontSizes } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

var theme = createTheme({
    palette: {
        primary: {
            main: '#304D6D',
        },
        secondary: {
            main: '#569656',
            offset: '#78ab78',
        },
        accent: {
            main: '#6d304d',
        },
        fadedGrey: {
            main: '#839192',
        },
        darkBlue:{
            light:'#1e2e7f',
            main:'#1e2e5f',
            main2:'#243e8g',
            dark:'#212d5f',
            contrastText: '#fff',
        },

        //styles used on rows
        alernateRows: {
            even: blueGrey[100],
            odd: '#fff',
            onHover: blueGrey[300],
        },
    },
    typography: {
        logo: {
            fontFamily: "'Helvetica',",
            fontWeight: 600,
            textTransform: 'uppercase',
            color: '#FFC300',
            'vertical-align': '-35%',
        },
    },
});

//responsive text sizes
theme = responsiveFontSizes(theme)

theme.palette.alernateRows.singleSelection = alpha(theme.palette.secondary.light, 0.8);
theme.palette.alernateRows.singleSelectionHover = alpha(theme.palette.secondary.light, 0.5);

// //selection of colors with high contrast between values
theme.palette.rotatingColorsMain = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.info.main,
    theme.palette.info.dark,
    theme.palette.primary.dark,
    theme.palette.warning.main,
];

//selection of colors with high contrast between values, dark preference
theme.palette.rotatingColorsDark = [
    theme.palette.primary.main,
    theme.palette.secondary.dark,
    theme.palette.info.main,
    theme.palette.info.dark,
];

//selection rotating colors with red preference
theme.palette.rotatingColorsError = ['#EC7063', theme.palette.error.main, '#A93226', '#CD5C5C'];



export { theme };
