import './bootstrap-theme.css'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Amplify from 'aws-amplify';

let devConfig = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_3LoarRjHU",
    "aws_user_pools_web_client_id": "6rmo5rdreo48901qn39p99jg6t",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "Auth": {
        "identityPoolId": "us-west-2:ee8b3363-847c-4b48-8f39-59c76a34d9cd",
        "region": "us-west-2",
        "identityPoolRegion": "us-west-2",
        "userPoolId": "us-west-2_3LoarRjHU",
        "userPoolWebClientId": "6rmo5rdreo48901qn39p99jg6t",

    },
    "geo": {
        "AmazonLocationService": {
            "maps": {
                "items": {
                    "BinMaxxCloudMap": {
                        style: "Default style"
                      },
                    "BinMaxxCloudSat": {
                        "style": "RasterEsriImagery"
                    }
                },
                "default": "BinMaxxCloudMap"
            },
            "search_indices": {
                "items": [
                    "Default"
                ],
                "default": "Default"
            },
            "region": "us-west-2"
        },
        "BinMaxxCloud2.0-Geocode": {
            "isDefault": true,
            "providerPlugin": "awscloudformation",
            "service": "PlaceIndex",
            "dataProvider": "Esri",
            "dataSourceIntendedUse": "SingleUse",
            "accessType": "AuthorizedUsers"
          },
    }
}

let prodConfig = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_cQRin4oRa",
    "aws_user_pools_web_client_id": "6ectjkmn30bfrvmk593svq4rj6",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "Auth": {
        "identityPoolId": "us-west-2:4cae4321-4e03-46b2-9291-c6eaa354be59",
        "region": "us-west-2",
        "identityPoolRegion": "us-west-2",
        "userPoolId": "us-west-2_cQRin4oRa",
        "userPoolWebClientId": "6ectjkmn30bfrvmk593svq4rj6",

    },
    "geo": {
        "AmazonLocationService": {
            "maps": {
                "items": {
                    "BinMaxxCloudMap": {
                        "style": "VectorEsriNavigation"
                    },
                    "BinMaxxCloudSat": {
                        "style": "RasterEsriImagery"
                    }
                },
                "default": "BinMaxxCloudMap"
            },
            "search_indices": {
                "items": [
                    "Binmaxx-Cloud-V2"
                ],
                "default": "Binmaxx-Cloud-V2"
            },
            "region": "us-west-2"
        },
    }
}

Amplify.configure(prodConfig);
ReactDOM.render( < App / > , document.getElementById('root'));