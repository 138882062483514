import React, { useEffect, useState, useContext, lazy, Suspense } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { Route, Switch,  useLocation } from "react-router-dom";
import { CompanyContext, UserContext } from "../Root";
import TableLoader from "./utils/tableLoader";
const ServiceStatus = lazy(()=>import("./userAW/page.serviceStatus"));
const Sandbox = lazy(()=>import("./page.sandbox"));
const Reports = lazy(()=>import("./reports/page.reports"));
const Dashboard = lazy(()=>import("./user/page.dashboard"));
const Customers = lazy(()=>import("./customer/page.customers"));
const CustomerMenu = lazy(()=>import("./customer/customer.main"));
const LocationMenu = lazy(()=>import("./location/location.main"));
const TrucksList = lazy(()=>import("./trucks/page.trucks.list"));
const TruckMenu = lazy(()=>import("./trucks/page.trucks.menu"));
const LiftsList = lazy(()=>import("./lifts/page.liftsList"));
const BinsList = lazy(()=>import("./route.bins/page.binsList"));
const LandfillList = lazy(()=>import("./landfill/page.landfill.list"));
const BinMenu = lazy(()=>import("./route.bins/page.bin.menu"));
const LandfillMenu = lazy(()=>import("./landfill/page.landfill.menu"));
const newBin = lazy(()=>import("./route.bins/page.newBin"));
const NewLandfill = lazy(()=>import("./landfill/page.newLandfill"));
const NewCustomer = lazy(()=>import("./customer/page.newCustomer"));
const NewLocation = lazy(()=>import("./location/page.newLocation"));
const UnAssocLifts = lazy(()=>import("./lifts/page.unassociatedLifts")) ;
const Users = lazy(()=>import("./users/page.users"));
const NewUser = lazy(()=>import("./users/page.newUser"));
const UserMenu = lazy(()=>import("./users/page.userMenu"));
const ImportCustomer = lazy(()=>import("./customer/page.importCustomers"));
const CompanyList = lazy(()=>import("./company/page.CompanyList"));
const PageNotFound = lazy(()=>import("./errorPages/pageNotFound"));
const NewCompany = lazy(()=>import("./company/page.newCompany")) ;
const CompanyMenu = lazy(()=>import("./company/page.companyMenu"));
const NewTruck = lazy(()=>import("./trucks/page.newTruck"));


const Main = (props) => {
    //context
    const usr = React.useContext(UserContext);
    const companyContext = useContext(CompanyContext)

    // const [usingCompany, setUsingCompany] = useState("No Company Selected");
    const [usingCompany, setUsingCompany] = useState(localStorage.getItem("theCompanyName"));
    const [newCompanyId, setNewCompanyId] = useState(parseInt(localStorage.getItem("theCompanyID")));
    
    function handleCompanyChange(newUsingCompany, newCompanyId) {
        setUsingCompany(newUsingCompany);
        setNewCompanyId(newCompanyId);
    }

    /*
    
        AirWeigh Administrator redirect on loss of company context

    */
    const location = useLocation()
    useEffect(() => {
        //routes admins can access without a company ID being set
        let awAdminRoutes = ['/companies','/company','/companies/new','/servicestatus','/user']

        if(location && usr.awAdmin){
            if(location.pathname === '/') return;
            else{
                let found = false
                for(let path of awAdminRoutes){
                    if(location.pathname.toLocaleLowerCase() === path){
                        found = true
                        break
                    }
                }

                console.log("HERE IS COMPANY CONTEXT")
                console.log(companyContext)
                if(!found && !companyContext.id && companyContext.id !== 0){
                    console.log("No Company Context. Rerouting to company selection")
                    window.location = ('/#/companies')
                }
            }
        }

    },[props,location])

    const getRoutes = () => {
        // default starting routes
        let routes = [
            <Route exact key='/customers' path='/customers' component={Customers} />,
            <Route exact key='/customersubmenu' path='/customersubmenu' component={CustomerMenu} />,
            <Route exact key='/locationSubMenu' path='/locationSubMenu' component={LocationMenu} />,
            <Route exact key='/trucks' path='/trucks' component={TrucksList} />,
            <Route exact key='/truckMenu' path='/truckMenu' component={TruckMenu} />,
            <Route exact key='/liftsList' path='/liftsList' component={LiftsList} />,
            <Route exact key='/Unassociated' path='/Unassociated' component={UnAssocLifts} />,
            <Route exact key='/bins' path='/bins' component={BinsList} />,
            <Route exact key='/binSubMenu' path='/binSubMenu' component={BinMenu} />,
            <Route exact key='/landfills' path='/landfills' component={LandfillList} />,
            <Route exact key='/landfillSubMenu' path='/landfillSubMenu' component={LandfillMenu} />,
            <Route exact key='/newBin' path='/newBin' component={newBin} />,
            <Route exact key='/newLandfill' path='/newLandfill' component={NewLandfill} />,
            <Route exact key='/newCustomer' path='/newCustomer' component={NewCustomer} />,
            <Route exact key='/importCustomer' path='/importCustomer' component={ImportCustomer} />,
            <Route exact key='/newLocation' path='/newLocation' component={NewLocation} />,
            <Route exact key='/defaultHomePage' path='/' component={Customers} />,
            <Route key='pageNotFound' component={PageNotFound} />,
        ];

        //Routes specifically for Customer Admins
        if (usr.admin) {
            routes.unshift(<Route exact key='adminHomePage' path='/' component={Dashboard} />);
        }

        //Routes for either Admins or AirWeigh Admins
        if (usr.admin || usr.awAdmin) {
            routes.unshift(
                <Route exact key='/users' path='/users' component={Users} />,
                <Route exact key='/newUser' path='/newUser' component={NewUser} />,
                <Route exact key='/user' path='/user' component={UserMenu} />,
                <Route exact key='/dashboard' path='/dashboard' component={Dashboard} />,
                <Route exact key='/reports' path='/reports' component={Reports} />,

            );
        }

        //Routes specifically for AirWeigh Admins
        if (usr.awAdmin) {
            routes.unshift(
                <Route exact key='awAdminHomePage' path='/' component={ServiceStatus} />,
                <Route exact key='/servicestatus' path='/servicestatus' component={ServiceStatus} />,
                <Route exact key='/companies' path='/companies' component={CompanyList} />,
                <Route exact key='/companies/new' path='/companies/new' component={NewCompany} />,
                <Route exact key='/company' path='/company' component={CompanyMenu} />,
                <Route exact key='/newTruck' path='/newTruck' component={NewTruck} />,
            );
        }

        routes.push(
            <Route exact key='/sandbox' path='/sandbox' component={Sandbox} />,
        )

        return routes;
    };

    return (
        <Suspense fallback={
            <Paper sx={{margin:2}} elevation={6}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Typography variant='h4' >Loading</Typography>
                    <Typography variant='overline' >Resource Loading, please wait...</Typography>
                </Stack>
                <TableLoader/>
            </Paper>
            }
        >
            <Switch>{getRoutes()}</Switch>
        </Suspense>
    );
};

export default Main;
