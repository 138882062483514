import React, {useContext} from 'react';
import { CompanyContext, UserContext } from "../Root"
import { Box, List, ListItemIcon, ListItemText, Paper, ListItemButton } from '@mui/material';
import CollapseList from './utils/util.collapseList';
import { makeStyles } from '@mui/styles';
import { LinkNoText } from './styledComponents/linkNoText';

const useStyles = makeStyles((theme) => ({
    sidebar: {
        position: "sticky", //transition between fixed and relative positioning depending on scroll
        top: 75, //keep the menu below the header
        zIndex:9999, //arbitrary value used to keep the header above all other content
        
    },
}));

const Sidebar = () => {

	//context
	var usr = useContext(UserContext)
    var company = useContext(CompanyContext)

    //themes & styles
    const classes = useStyles();

    //list item creation
	const createLI = (name,link, icon, indented) => {
		var indent = indented ? 5 : 0
		return (
            <LinkNoText href={`/#/${link}`}>
                <ListItemButton sx={{ pl: indent }} dense>
                    {icon && <ListItemIcon>
                        {icon}
                    </ListItemIcon>}
                    <ListItemText primary={name} />
                </ListItemButton>
            </LinkNoText>
		)
	}

	return (
			<Paper className={classes.sidebar} elevation={9} >
				<Box paddingLeft={1}>
					<List>
						{/* AirWeigh Personel Only */}
						{usr.awAdmin && (
							<CollapseList title="AW Admins">
								{createLI("Service Status","servicestatus", false, true)}
								{createLI("Companies","companies", false, true)}
							</CollapseList>
						)}

						{/* Admins Only */}
						{((usr.admin && ! usr.awAdmin) || (usr.awAdmin && company.company) ) && (
							<CollapseList title="Admins">
								{createLI("Dashboard","dashboard", false, true)}
								{createLI("Reports","reports", false, true)}
								{createLI("Users","users", false, true)}
							</CollapseList>
						)}

						{/* All Users */}
						{(!usr.awAdmin || (usr.awAdmin && company.company) ) && (
							<React.Fragment>
								{createLI("Customers","customers", false, false)}
								{createLI("Lifts","liftslist", false, false)}
                                {createLI("Unassigned Lifts","Unassociated", false, false)}
								{createLI("Trucks","trucks", false, false)}
								{createLI("Bins","bins", false, false)}
								{createLI("Landfills","landfills", false, false)}
							</React.Fragment>
						)}

					</List>
				</Box>
			</Paper>
	)

}
export default Sidebar;