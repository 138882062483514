import React from 'react';
import { Authenticator, AmplifyProvider} from '@aws-amplify/ui-react';
import { alpha, ThemeProvider } from '@mui/material';
import { theme } from './theme.js';
import './index.css';
import HomePage from './homepage.jsx';

//App handles authentication and the landing page layout
const App = () => {
    const amplifyTheme = {
        name: 'Auth Example Theme',
        tokens: {
            colors: {
                brand: {
                    primary: {
                        value: '#243e8f',
                    },
                },
                font: {
                    interactive: {
                        value: '#243e8f',
                    },
                    _hover: {
                        backgroundColor: { value: alpha('#212d5f', 0.8) },
                    },

                },
            },
            components: {
                button: {
                    primary: {
                        backgroundColor: {
                            value: '#243e8f',
                        },
                        _hover: {
                            backgroundColor: { value: alpha('#569656', 0.7)  },
                        },
                    },
                    link: {
                        _hover: {
                            backgroundColor: { value: alpha('#569656', 0.35) },
                        },
                    },
                },
            },
        },
    };

    return (
        <Authenticator.Provider>
            <AmplifyProvider theme={amplifyTheme}>
                <ThemeProvider theme={theme}>
                    <HomePage />
                </ThemeProvider>
            </AmplifyProvider>
        </Authenticator.Provider>
    );
};
export default App;
