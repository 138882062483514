import { Box, } from "@mui/material";
import { styled, } from "@mui/styles";

export const RoundedBox = styled(Box)(({ theme }) => ({
    borderRadius: '20px 45px 15px 45px',
    display:'flex',
    justifyContent:"center",
    alignItems:"center",
    paddingRight:10,
    paddingLeft:10,
}));