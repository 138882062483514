import React from "react";
import Main from "./layout.main.jsx";
import Sidebar from "./layout.sidebar.jsx";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

const Body = () => {
    return (
        <Grid container padding={0.5} paddingBottom={4}>

            <Grid item xs={1.25} sx={{minWidth:150,maxWidth:250}}>
                    <Sidebar />
            </Grid>

            {/* xs={true} fills the remaining width */}
            <Grid item xs={true} >
                <Main />
            </Grid>
        </Grid>
    );
};
export default Body;
