import React from 'react';
import { Typography, Popover, Box } from '@mui/material';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';

const PopContent = (props) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'pop1' });

    return (
        <React.Fragment>
            <Box {...bindTrigger(popupState)}>{props.label}</Box>
            <Popover
                {...bindPopover(popupState)}
                sx={{ zIndex: (theme) => 10000 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography component='div'>{props.children}</Typography>
            </Popover>
        </React.Fragment>
    );
};

export default PopContent;
